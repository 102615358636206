import React from 'react';
import Textarea from '~/app/modules/form/Textarea';
import File from '~/app/modules/form/File';
import MForm from '~/app/modules/form';
import classes from './style.module.scss';
import useTranslation from '~/app/hooks/useTranslation';
import Select from '~/app/modules/form/Select';
import useForm from "~/app/modules/form/useForm";
import {IssuePayload} from "~/types";
import {AddIssueProps} from "~/issues/Add";
import Category from "~/issues/Add/Form/Details/Category";
import {useIssueTypes} from "~/app/data/statuses/issue";
import {useStoreState} from "store/index";

export interface DetailsProps {
    setShowDetails: (boolean) => void
    type: AddIssueProps['type']
}

export type DetailsComponent = React.FC<DetailsProps>;

const Details: DetailsComponent = ({setShowDetails, type}) => {
    const {t} = useTranslation('issue');
    const {values, fieldEvents} = useForm<IssuePayload>({formName: 'addIssue'});
    const issueTypes = useIssueTypes();
    const users = useStoreState(state => state.app.appData.users.map(user => ({text: user.name, value: user.id})));
    const departments = useStoreState(state => state.app.appData.departments.map(department => ({
        text: department.name,
        value: department.id
    })));

    // console.log('tupac');

    return (
        <MForm className={`${classes['form']} ${classes['details']}`}>
            <Category setShowDetails={setShowDetails}/>
            {type === 'issue' && <Select
                className={`${classes['input-container']}`}
                label={t('classification.title')}
                name="type"
                placeholder={t('classification.placeholder')}
                options={issueTypes}
                icon={'keyboard_arrow_down'}
                onChange={fieldEvents.onChange}
                defaultValue={values.type}
            />}
            <Textarea
                className={`${classes['input-container']}`}
                label={t('description.title')}
                placeholder={t('description.placeholder')}
                descriptionPosition="top"
                name="description"
                defaultValue={values.description}
                {...fieldEvents}
            />
            <File
                className={`${classes['input-container']} ${classes['file']}`}
                label={t('images.title')}
                placeholder={t('images.placeholder')}
                name='images'
                onChange={fieldEvents.onChange}
                maxFiles={3}
                preview={true}
                defaultValue={values.images}
            />
            {type === 'issue' && <Select
                className={`${classes['input-container']}`}
                label={t('department.title')}
                name="departmentId"
                placeholder={t('department.placeholder')}
                options={departments}
                icon={'keyboard_arrow_down'}
                onChange={fieldEvents.onChange}
                defaultValue={values.departmentId}
                showEmptyOption={true}
                emptyOptionValue={0}
            />}
            {type === 'repair' && <Select
                className={`${classes['input-container']}`}
                label={t('user.title')}
                name="performedBy"
                placeholder={t('user.placeholder')}
                options={users}
                icon={'keyboard_arrow_down'}
                onChange={fieldEvents.onChange}
                defaultValue={values.performedBy}
            />}
        </MForm>
    );
};

export default Details
