import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { localStorageGet, localStorageSet } from '~/app/helpers/localStorage';

import { useStoreState, useStoreActions } from '~/store';

import { Schemas } from './schemas';
import QueryBuilder from './services/queryBuilder';
import Loader from "~/app/common/Loader";
import { syncResponse } from '~/types';
import useWebSocket from '~/app/hooks/useWebSocket';
import NetworkErrorMessage from './NetworkErrorMessage';

export type SQLiteContextProps = {
  connection?: SQLitePlugin.Database
}

export const SQLiteContext = React.createContext<SQLiteContextProps>({});

const SQLiteProvider = ({ children }) => {
  const [syncResult, setSyncResult] = useState<syncResponse>({});
  const [loading, setLoading] = useState<boolean>(!!window.sqlitePlugin);
  const [updatingDB, setUpdatingDB] = useState<boolean>(!!window.sqlitePlugin);
  const [networkError, setNetworkError] = useState<boolean>(false);
  const setSQLiteConnection = useStoreActions(actions => actions.app.offline.SetSQLiteConnection);
  const netWorkStatus = useStoreState(state => state.app.offline.status);
  const appDataActions = useStoreActions(actions => actions.app.appData);

  const isUserAuthenticated = useStoreState(state => state.user.isUserAuthenticated);
  const { syncIssues } = useWebSocket();

  const fetchFavoriteMachinesIssues = useStoreActions(actions => actions.issue.fetchFavoriteMachinesIssues);
  const favoritesMachinesFetchAction = useStoreActions(actions => actions.machines.fetchFavorites);

  const fetchFavoriteMachinesSpecificationsAction = useStoreActions(actions => actions.documentation.fetchFavoriteMachinesSpecifications);

  const issueActions = useStoreActions(actions => actions.issue);

  const fetchUnreadNotificationsCountAction = useStoreActions(actions => actions.notification.fetchUnreadNotificationsCount);
  const resetNotifications = useStoreActions(actions => actions.notification.resetNotifications);

  const SQLiteConnection = useMemo(() => (
    window.sqlitePlugin
      ? window.sqlitePlugin.openDatabase({
        name: 'driverApp.db',
        location: 'default',
      }, () => setLoading(false), () => setLoading(false))
      : undefined
  ), []);

  const loadAppData = useCallback(async () => {
    if (!netWorkStatus) {
      await appDataActions.fetchDepartments();
      await issueActions.fetchIssueCategories();
      await appDataActions.fetchUsers();

      await fetchUnreadNotificationsCountAction();
      await favoritesMachinesFetchAction();
      await fetchFavoriteMachinesIssues('all');
      await fetchFavoriteMachinesSpecificationsAction();
    } else {
      resetNotifications();
      await appDataActions.getOfflineDepartments();
      await issueActions.getOfflineIssueCategories();
      await appDataActions.getOfflineUsers();
    }
  }, [netWorkStatus, appDataActions, issueActions, fetchFavoriteMachinesIssues, favoritesMachinesFetchAction, fetchFavoriteMachinesSpecificationsAction, fetchUnreadNotificationsCountAction, resetNotifications]);

  const recreateDatabase = useCallback(async () => {

    if (isUserAuthenticated) {
      if (SQLiteConnection) {
        if (!netWorkStatus) {
          await appDataActions.sync().then(response => response && setSyncResult(response));
        }

        const dbVersionChanged = process.env.REACT_APP_VERSION !== localStorageGet('REACT_APP_VERSION');
        const qb = new QueryBuilder();

        if (dbVersionChanged) {
          if (!netWorkStatus) {
            setNetworkError(false);
            SQLiteConnection.transaction(tx => {
              Object.keys(Schemas).map(tableName => {
                tx.executeSql(qb.dropTable(tableName));

                if (Schemas[tableName].length) {
                  tx.executeSql(qb.createTable(tableName));
                }

                return '';
              });
            });
            localStorageSet('REACT_APP_VERSION', process.env.REACT_APP_VERSION || '');
          } else {
            setNetworkError(true);
          }
        }
      }

      if (SQLiteConnection || !window.sqlitePlugin) {
        loadAppData().then();
      }
    }

    setUpdatingDB(false);

  }, [SQLiteConnection, netWorkStatus, isUserAuthenticated, appDataActions, loadAppData, setUpdatingDB]);

  useEffect(() => {
    setSQLiteConnection(SQLiteConnection);

    return () => {
      if (SQLiteConnection) {
        SQLiteConnection.close();
      }
    }
  }, [SQLiteConnection, setSQLiteConnection]);

  useEffect(() => {
    recreateDatabase();
  }, [recreateDatabase]);

  useEffect(() => {
    if (syncResult && syncResult.issueIds) {
      syncIssues(syncResult.issueIds);
    }
  }, [syncResult, syncIssues]);

  if (networkError) {
    return <NetworkErrorMessage />;
  }

  if (loading || updatingDB) {
    return <Loader size="32" />;
  }

  return (
    <SQLiteContext.Provider value={{ connection: SQLiteConnection }}>
      {children}
    </SQLiteContext.Provider>
  );
}
export default SQLiteProvider;
