import { User } from '~/types';
import _ from 'lodash'

export const getShortName = (name: User['name']): string => {
    if(name === '') return "";
    const nameArray = name.split(' ');
    if (nameArray.length === 1) {
      return `${nameArray[0].charAt(0).toUpperCase()}`;
    }
    return `${nameArray[0].charAt(0).toUpperCase()}${nameArray[1].charAt(0).toUpperCase()}`;
};

export const getFileNameFromUrl = (url: string | undefined) => {
    if(!url) {
        return ''
    }
    return url.split('?')[0].split('/').pop()
}

export const parseBodyFields = (body: {[key: string]: any}, fields: string[]): {[key: string]: any} => {
    return _.pick(body, fields);
};
