import React, {useCallback} from 'react';
import classes from './style.module.scss';
import Button from 'app/common/Button/index';
import useTranslation from 'app/hooks/useTranslation';
import useForm from "~/app/modules/form/useForm";
import {IssuePayload, Machine} from "~/types";
import {useStoreActions, useStoreState} from "~/store";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";
import {notify} from "~/app/helpers/notify";
import useWebSocket from "~/app/hooks/useWebSocket";
import {AddIssueProps} from "~/issues/Add";
import { generateGUID } from '~/app/helpers/guid';

export interface FooterProps {
    onClose: () => void
    showDetails: boolean
    setShowDetails: (boolean) => void
    type: AddIssueProps['type']
}

export type FooterComponent = React.FC<FooterProps>;

const Footer: FooterComponent = ({onClose, showDetails, setShowDetails, type}) => {
    const {t} = useTranslation('issue');
    const {syncIssues} = useWebSocket();
    const {id} = useParams<{ id: Machine['id'] }>();
    const {values, formEvents: {onSubmit}, formState} = useForm<IssuePayload>({formName: 'addIssue'});
    const saveIssueAction = useStoreActions(actions => actions.issue.saveIssue);
    const saveOfflineIssueAction = useStoreActions(actions => actions.issue.saveOfflineIssue);
    const issueUpdating = useStoreState(state => state.issue.issueUpdating);
    const updateCheckData = useStoreActions(actions => actions.periodicCheck.updateCheckData);
    const currentCheck = useStoreState(state => state.periodicCheck.currentCheck);
    const MachineFetchAction = useStoreActions(actions => actions.machine.fetchMachine);
    const MachineOfflineFetchAction = useStoreActions(actions => actions.machine.fetchOfflineMachine);
    const MachineInFavorite = useStoreState(state => state.machines.inFavorite);
    const IssueFetchAction = useStoreActions(actions => actions.issue.fetchIssue);
    const netWorkStatus = useStoreState(state => state.app.offline.status);

    const nextPreviousClickHandler = useCallback((): void => {
        setShowDetails(!showDetails);
    }, [setShowDetails, showDetails]);

    const saveClickHandler = useCallback((): void => {
        onSubmit(({formState: {invalid}}) => {
            if (invalid) {
                toast.error(t('error.required'));
                return;
            }
            const payload: IssuePayload = { ...values, guid: generateGUID() } as IssuePayload;
            (netWorkStatus ? saveOfflineIssueAction : saveIssueAction)(payload).then(async (data) => {
                if (currentCheck) {
                    currentCheck.issues.push(data);
                    currentCheck.status = 2;
                    updateCheckData(currentCheck);
                }
                onClose();
                notify(t(`success.${data.type === 3 ? 'repair' : 'issue'}`), 'success');
                netWorkStatus === 0 && syncIssues([data.id]);
                await (netWorkStatus ? MachineOfflineFetchAction : MachineFetchAction)(id);
                (netWorkStatus === 0 && MachineInFavorite(id)) && await IssueFetchAction(data.id);
            });
        })();
    }, [values, saveIssueAction, onClose, onSubmit, t, currentCheck, updateCheckData, MachineFetchAction, id, syncIssues, IssueFetchAction, netWorkStatus, MachineInFavorite, saveOfflineIssueAction, MachineOfflineFetchAction]);

    return (
        <div className={`${classes['footer']}`}>
            {!showDetails && <Button
                className={classes['abort']}
                onClick={onClose}>{t('cancel')}</Button>}
            {!showDetails && <Button
                className={classes['submit']}
                disabled={values.categoryId ? false : true}
                onClick={nextPreviousClickHandler}>{t('next')}</Button>}
            {showDetails && <Button
                className={`${classes['abort']}`}
                onClick={nextPreviousClickHandler}>{t('previous')}</Button>}
            {showDetails && <Button
                className={classes['submit']}
                disabled={(values.categoryId && values.description && !(type === 'issue' && !values.departmentId) && formState.touched && !formState.invalid) ? false : true}
                loading={issueUpdating}
                onClick={saveClickHandler}>{t('registerFail')}</Button>}
        </div>
    );
};

export default Footer
