import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { required } from '~/app/helpers/validation';

import Loader from '~/app/common/Loader';

import useForm, { UseFormOptionFields } from '~/app/modules/form/useForm';

import { IssuePayload, Machine, Project } from '~/types';

import { useStoreState } from '~/store';

import { AddIssueProps } from '../';

import Footer from './Footer';
import Categories from './Categories';
import Details from './Details';

import classes from './style.module.scss';

export type FormProps = {
  type: AddIssueProps['type']
  inspectionType?: AddIssueProps['inspectionType']
  onClose(): void
}

export type FormComponent = React.FC<FormProps>

const Form: FormComponent = ({ onClose, type, inspectionType }) => {
  const [showDetails, setShowDetails] = useState<boolean>(false);

  const { id, projectId } = useParams<{ id: Machine['id'], projectId: Project['id'] }>();

  const currentUserId = useStoreState(state => state.user.data.id);

  const formFields = useMemo<UseFormOptionFields>(() => ([
    'issueType',
    'machineId',
    'projectId',
    ['categoryId', { validators: [['required', required]] }],
    'categoryName',
    ['description', { validators: [['required', required]] }],
    'images',
    type === 'issue' ? ['departmentId', { validators: [['required', required]] }] : 'departmentId',
    type === 'repair' ? ['performedBy', { validators: [['required', required]] }] : 'performedBy',
    ['type', { validators: [['required', required]] }],
    ...inspectionType !== undefined ? ['inspectionType'] : []
  ]), [type, inspectionType]);

  const { values } = useForm<IssuePayload>({
    formName: 'addIssue',
    fields: formFields,
    defaultValues: {
      issueType: type,
      machineId: id,
      projectId: projectId,
      departmentId: 0,
      performedBy: type === 'repair' && currentUserId ? currentUserId : 0,
      categoryId: 0,
      type: type === 'repair' ? 3 : 0,
      ...inspectionType !== undefined && {inspectionType: inspectionType},
      images: [],
    },
  });

  if (!values) {
    return <Loader size="32"/>;
  }

  return (
    <>
      <div className={classes['container']}>
        {showDetails ? <Details setShowDetails={setShowDetails} type={type} /> : <Categories />}
      </div>

      <Footer onClose={onClose} showDetails={showDetails} setShowDetails={setShowDetails} type={type}/>
    </>
  );
};

export default Form
