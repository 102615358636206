import React from 'react';
import Modal from '~/app/common/Modal';
import classes from './style.module.scss';
import Header from './Header';
import Form from './Form';
import {IssuePayload} from "~/types";

export interface AddIssueProps {
    onClose: () => void
    open: boolean
    type: IssuePayload['issueType']
    inspectionType?: IssuePayload['inspectionType']
}

export type AddIssueComponent = React.FC<AddIssueProps>
const AddIssue: AddIssueComponent = ({onClose, open, type, inspectionType}) => {
    return (
        <Modal open={open} position={'top-center'} className={classes['add-issue']}>
            <Header type={type}/>
            <Form onClose={onClose} type={type} inspectionType={inspectionType}/>
        </Modal>
    );
};

export default AddIssue
