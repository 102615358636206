import React from 'react';
import classes from './style.module.scss';
import Icon from '~/app/common/Icon';
import useTranslation from '~/app/hooks/useTranslation';
import {AddIssueProps} from './../index';

export interface HeaderProps {
    type: AddIssueProps['type']
}

export type HeaderComponent = React.FC<HeaderProps>;

const Header: HeaderComponent = ({type}) => {
    const {t} = useTranslation('issue');
    return (
        <div className={classes['model-header']}>
            <Icon name={type === 'repair' ? 'build' : 'report_problem'} className={classes['icon']}/>
            <div className={classes['header-content']}>
                <label
                    className={classes['title']}>{t(type === 'repair' ? 'addRepair' : 'addIssue')}</label>
            </div>
        </div>
    );
};

export default Header
